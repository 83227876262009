import { PlayerAbstract } from './player-abstract';
import { isDirectionsCompatible, KEY_TO_DIRECTION_MAP } from '../../../directions';
import { TILE_CONTENT_TYPE } from '../../../const';
import * as PIXI from 'pixi.js';
import SpritePlayerCurrent from '../../../../sprites/player_current.png';

export class PlayerCurrent extends PlayerAbstract {
    type: TILE_CONTENT_TYPE = TILE_CONTENT_TYPE.PLAYER_CURRENT_HEAD;

    constructor(pixi: PIXI.Application) {
        super(pixi, SpritePlayerCurrent);
    }

    handleKeyboardEvents(event: KeyboardEvent) {
        const {code: key} = event;
        const directionNext = KEY_TO_DIRECTION_MAP[key];
        if (!directionNext || !isDirectionsCompatible(directionNext, this.direction)) {
            return;
        }

        event.preventDefault();
        this.directionQueue.push(directionNext)
    }
}
