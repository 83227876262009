import * as PIXI from 'pixi.js';

import { TileContent } from './index';
import { TILE_CONTENT_TYPE } from '../../const';

import SpriteFoodVariant1 from '../../../sprites/food_variant_1.png';
import SpriteFoodVariant2 from '../../../sprites/food_variant_2.png';
import SpriteFoodVariant3 from '../../../sprites/food_variant_3.png';

export enum FoodSize {
    Large = 'LARGE',
    Middle = 'MIDDLE',
    Small = 'SMALL'
}

const foodPresets = {
    [FoodSize.Large]: {
        sprite: SpriteFoodVariant1,
        score: 1,
        scale: 0.4,
    },
    [FoodSize.Middle]: {
        sprite: SpriteFoodVariant2,
        score: 2,
        scale: 0.5,
    },
    [FoodSize.Small]: {
        sprite: SpriteFoodVariant3,
        score: 3,
        scale: 0.6,
    }
}

export class Food extends TileContent {
    scale = 0.5
    readonly type = TILE_CONTENT_TYPE.FOOD
    public readonly score: number;

    constructor(pixi: PIXI.Application, size: FoodSize) {
        const {score, scale, sprite} = foodPresets[size];
        super(pixi, sprite);
        this.scale = scale;
        this.score = score;
    }
}
