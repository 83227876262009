import styles from './GameControls.module.css';
import { DIRECTION } from '../../engine/directions';
import { DirectionButton } from '../DirectionButton/DirectionButton';

export function GameControls() {
    return (
        <div className={styles.GameControls}>
            <div className={styles.GameControlsRow}>
                <DirectionButton direction={DIRECTION.UP} />
            </div>
            <div className={styles.GameControlsRow}>
                <DirectionButton direction={DIRECTION.LEFT} />
                <DirectionButton direction={DIRECTION.RIGHT} />
            </div>
            <div className={styles.GameControlsRow}>
                <DirectionButton direction={DIRECTION.DOWN} />
            </div>
        </div>
    )
}
