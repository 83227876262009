const DEFAULT_TILE_SIZE = 20;

export const TILE_SIZE = {
    WIDTH: DEFAULT_TILE_SIZE,
    HEIGHT: DEFAULT_TILE_SIZE
}

export enum TILE_CONTENT_TYPE {
    ABSTRACT,
    EMPTY,
    WALL,
    FOOD,
    PLAYER_ABSTRACT_HEAD,
    PLAYER_CURRENT_HEAD,
    PLAYER_CURRENT_TAIL,
    PLAYER_NPC_HEAD,
    PLAYER_NPC_TAIL,
    PLAYER_OTHER_HEAD,
    PLAYER_OTHER_TAIL
}

export const TILE_CONTENT_UNSAFE_TYPES = [
    TILE_CONTENT_TYPE.WALL,
    TILE_CONTENT_TYPE.PLAYER_CURRENT_HEAD,
    TILE_CONTENT_TYPE.PLAYER_CURRENT_TAIL,
    TILE_CONTENT_TYPE.PLAYER_NPC_HEAD,
    TILE_CONTENT_TYPE.PLAYER_NPC_TAIL,
    TILE_CONTENT_TYPE.PLAYER_OTHER_HEAD,
    TILE_CONTENT_TYPE.PLAYER_OTHER_TAIL,
];

export enum GAME_STATE {
    ALIVE,
    LOST,
    WON
}
