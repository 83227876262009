import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import IconDirectionButton from './DirectionButton.png';

import styles from './DirectionButton.module.css';
import { CubeSnakeEngine } from '../../engine';
import { DIRECTION } from '../../engine/directions';

export interface DirectionButtonProps {
    direction: DIRECTION;
    isDisabled?: boolean;
    onMouseDown?: (direction: DIRECTION) => void;
}

const angleMap: Record<DIRECTION, number> = {
    [DIRECTION.NONE]: 0,
    [DIRECTION.UP]: 0,
    [DIRECTION.RIGHT]: 90,
    [DIRECTION.DOWN]: 180,
    [DIRECTION.LEFT]: 270,
}

function getTransform(mode: DIRECTION): string | null {
    const angle = angleMap[mode];
    return angle ? `rotate(${angle}deg)` : null;
}

function getIconStyle(mode: DIRECTION) {
    const transform = getTransform(mode);
    return transform ? {transform} : undefined;
}

function getClickableAreaStyle(mode: DIRECTION) {
    const heightDefault = 50;
    const widthDefault = 50;
    let width = widthDefault;
    let height = heightDefault;
    let top = 0;
    let left = 0;
    let backgroundColor = 'rgba(255, 0, 0, .5)';

    if (mode === DIRECTION.DOWN) {
        width = widthDefault * 4;
        height = heightDefault * 4;
        left = -width / 2.7
        top = 18
    }

    if (mode === DIRECTION.UP) {
        width = widthDefault * 4;
        height = heightDefault * 4;
        top = -height + 34;
        left = -width / 2.7
        backgroundColor = 'rgba(255, 0, 255, .5)';
    }

    if (mode === DIRECTION.RIGHT) {
        width = widthDefault * 4;
        height = heightDefault * 4;
        left = 17;
        top = -height / 2.7;
        backgroundColor = 'rgba(255, 255, 0, .5)';
    }

    if (mode === DIRECTION.LEFT) {
        width = widthDefault * 4;
        height = heightDefault * 4;
        left = -width + 35;
        top = -height / 2.7;
        backgroundColor = 'rgba(0, 255, 0, .5)';
    }

    return {width, height, top, left, backgroundColor};
}

export function DirectionButton({direction, isDisabled, onMouseDown}: DirectionButtonProps) {
    const iconStyle = useMemo(() => {
        return getIconStyle(direction);
    }, [direction]);
    const handleClick = useCallback(() => {
        CubeSnakeEngine.player?.setDirectionNext(direction);
        onMouseDown?.(direction);
    }, [direction, onMouseDown]);

    const className = cn(styles.DirectionButton, {
        [styles.DirectionButtonDisabled]: isDisabled
    })

    const clickableAreaStyle = useMemo(() => {
        return getClickableAreaStyle(direction);
    }, [direction]);

    return (
        <div className={className}>
            <div
                className={styles.DirectionButtonClickableArea}
                style={clickableAreaStyle}
                onMouseDown={handleClick}
            />
            <img
                className={styles.Icon}
                alt="Direction"
                src={IconDirectionButton}
                style={iconStyle}
            />
        </div>
    );
}