import * as PIXI from 'pixi.js';

const pixi: PIXI.Application = new PIXI.Application({
    backgroundColor: '#ffffff',
});

class Renderer {
    // Temporary
    get pixi(): PIXI.Application {
        return pixi;
    }

    public resize(element: HTMLElement): void {
        pixi.resizeTo = element;
        pixi.resize();
    }
}

export const CubeSnakeRenderer = new Renderer();