import _ from 'lodash';

import { useCallback, useEffect, useRef, useState } from 'react';

import { CubeSnakeEngine, EngineEvent } from '../../engine';

import styles from './Game.module.css';
import { TILE_SIZE } from '../../engine/const';

function computeContainerSize(containerHeight: number, containerWidth: number) {
    if (containerHeight === 0 || containerWidth === 0) {
        return null;
    }

    const tileCountVertical = Math.floor(containerHeight / TILE_SIZE.HEIGHT);
    const tileCountHorizontal = Math.floor(containerWidth / TILE_SIZE.WIDTH);
    const height = tileCountVertical * TILE_SIZE.HEIGHT;
    const width = tileCountHorizontal * TILE_SIZE.WIDTH;

    return { height, width };
}

export function Game() {
    const [style, setStyle] = useState<{height: number, width: number} | null>(null);
    const gameRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        if (gameRef.current !== null && style !== null) {
            CubeSnakeEngine.setup(gameRef.current);
        }
    }, [style, gameRef]);

    useEffect(() => {
        if (containerRef.current === null) {
            return;
        }
        const {offsetHeight, offsetWidth} = containerRef.current;
        const styleNext = computeContainerSize(offsetHeight, offsetWidth);
        setStyle(styleNext);
    }, [containerRef]);

    const handleWindowResize = useCallback(_.debounce(() => {
        if (containerRef.current === null) {
            return;
        }
        const {offsetHeight, offsetWidth} = containerRef.current;
        const styleNext = computeContainerSize(offsetHeight, offsetWidth);
        setStyle(styleNext);
    }, 100), [containerRef]);

    useEffect(() => {
        CubeSnakeEngine.handleWindowResize();
    }, [style])

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [/*handleResize,*/ handleWindowResize]);

    return (
        <div ref={containerRef} className={styles.GameContainer}>
            <div ref={gameRef} className={styles.Game} style={style || {}} />
        </div>
    )
}