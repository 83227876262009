import { TileContent } from '../index';

import { TILE_CONTENT_TYPE, TILE_CONTENT_UNSAFE_TYPES } from '../../../const';
import { PositionSurroundings, TilePositionMeta } from '../../../area';
import { Tile } from '../../index';
import { DIRECTION, isDirectionsCompatible } from '../../../directions';

const DEFAULT_PLAYER_SCALE = 1;

export class PlayerAbstract extends TileContent {
    readonly type: TILE_CONTENT_TYPE = TILE_CONTENT_TYPE.ABSTRACT; // Override

    protected direction: DIRECTION = DIRECTION.NONE;
    protected directionQueue: DIRECTION[] = [];
    // protected directionNext: DIRECTION = DIRECTION.NONE;
    tailCount: number = 0;
    scale: number = DEFAULT_PLAYER_SCALE;

    public grow(score: number = 1): void {
        this.tailCount += score;
    }

    public reset(): void {
        this.direction = DIRECTION.NONE;
        // this.directionNext = DIRECTION.NONE;
        this.tailCount = 0;
        this.scale = DEFAULT_PLAYER_SCALE;
    }

    // TODO: Separate file?
    public isSafeTile(tile: Tile): boolean {
        const {contentType} = tile;
        if (!contentType) {
            return false;
        }

        return !TILE_CONTENT_UNSAFE_TYPES.includes(contentType);
    }

    public getNextPosition(positionSurroundings: PositionSurroundings): TilePositionMeta | null {
        const positionMeta = positionSurroundings[this.direction];
        if (this.direction === DIRECTION.NONE) {
            return positionMeta;
        }
        const {tile} = positionMeta;
        return tile !== null && this.isSafeTile(tile) ? positionMeta : null;
    }

    public setDirectionNext(directionNext: DIRECTION): void {
        if (!isDirectionsCompatible(directionNext, this.direction)) {
            return;
        }

        this.directionQueue.push(directionNext);
    }

    public shiftDirection(): void {
        this.direction = this.directionQueue.shift() || this.direction;
    }
}
