import React from 'react';

import './App.css';
import { Game } from '../Game/Game';
import { GameFooter } from '../GameFooter/GameFooter';
import { GameHeader } from '../GameHeader/GameHeader';

function App() {
    return (
        <div className="app">
            <GameHeader/>
            <Game/>
            <GameFooter/>
        </div>
  );
}

export default App;
