import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { TileContent } from './index';

import { TILE_CONTENT_TYPE } from '../../const';

import SpriteWallVariant1 from '../../../sprites/wall_variant_1.png';
import SpriteWallVariant2 from '../../../sprites/wall_variant_2.png';
import SpriteWallVariant3 from '../../../sprites/wall_variant_3.png';
import SpriteWallVariant4 from '../../../sprites/wall_variant_4.png';

const sprites: string[] = [SpriteWallVariant1, SpriteWallVariant2, SpriteWallVariant3, SpriteWallVariant4];

export class Wall extends TileContent {
    readonly type = TILE_CONTENT_TYPE.WALL

    constructor(pixi: PIXI.Application) {
        const sprite = _.sample(sprites) || SpriteWallVariant1;
        super(pixi, sprite)
    }
}
