import * as PIXI from 'pixi.js';
import { TILE_CONTENT_TYPE, TILE_SIZE } from '../../const';

export interface TileContentAdjustOptions {
    x: number;
    y: number;
    width: number;
    height: number;
}

export class TileContent {
    entity: PIXI.Sprite;
    scale: number = 1;
    readonly type: TILE_CONTENT_TYPE = TILE_CONTENT_TYPE.ABSTRACT;
    protected pixi: PIXI.Application;

    constructor(pixi: PIXI.Application, sprite: string) {
        this.entity = PIXI.Sprite.from(sprite);
        this.pixi = pixi;
    }

    adjust({x, y, height, width}: TileContentAdjustOptions) {
        this.entity.width = Math.floor(width * this.scale);
        this.entity.height = Math.floor(height * this.scale);

        const offsetX = Math.floor((TILE_SIZE.WIDTH - this.entity.width) / 2);
        const offsetY = Math.floor((TILE_SIZE.HEIGHT - this.entity.height) / 2);
        this.entity.x = x + offsetX;
        this.entity.y = y + offsetY;
    }
}