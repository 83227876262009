import * as PIXI from 'pixi.js';
import SpriteTileEmpty from '../../sprites/tile_empty.png';
import { TILE_CONTENT_TYPE, TILE_SIZE } from '../const';
import { TileContent } from './content';

export class Tile {
    entity: PIXI.Sprite = PIXI.Sprite.from(SpriteTileEmpty);
    private pixi: PIXI.Application;
    content: TileContent | null = null;
    verticalIndex: number;
    horizontalIndex: number;

    constructor(pixi: PIXI.Application, verticalIndex: number, horizontalIndex: number) {
        this.pixi = pixi;
        this.entity.height = TILE_SIZE.HEIGHT;
        this.entity.width = TILE_SIZE.WIDTH;
        this.verticalIndex = verticalIndex;
        this.horizontalIndex = horizontalIndex;
    }

    get contentType() {
        return this.content?.type || TILE_CONTENT_TYPE.EMPTY;
    }

    destroy() {
        if (this.content) {
            this.pixi.stage.removeChild(this.content.entity as PIXI.DisplayObject);
        }
    }

    isEmpty() {
        return this.content === null;
    }

    setPosition(x: number, y: number) {
        this.entity.x = x;
        this.entity.y = y;
    }

    setContent(content: TileContent | null) {
        if (this.content !== null) {
            this.pixi.stage.removeChild(this.content.entity as PIXI.DisplayObject);
        }

        this.content = content;

        if (this.content === null) {
            return;
        }

        this.content.adjust({
            x: this.entity.x,
            y: this.entity.y,
            height: this.entity.height,
            width: this.entity.width,
        });
        this.pixi.stage.addChild(this.content.entity as PIXI.DisplayObject);
    }
}