import * as PIXI from 'pixi.js';
import _ from 'lodash';

import { Tile } from './tile';
import { Food, FoodSize } from './tile/content/food';
import { GAME_STATE, TILE_CONTENT_TYPE, TILE_SIZE } from './const';
import { Wall } from './tile/content/wall';
import { Player } from './tile/content/playable';
import { Tail } from './tile/content/tail';
import { DIRECTION } from './directions';

export interface TilePositionMeta {
    i: number;
    j: number;
    tile: Tile | null
}

export interface PositionSurroundings {
    [DIRECTION.NONE]: TilePositionMeta // Current position
    [DIRECTION.LEFT]: TilePositionMeta,
    [DIRECTION.RIGHT]: TilePositionMeta,
    [DIRECTION.UP]: TilePositionMeta,
    [DIRECTION.DOWN]: TilePositionMeta
}

export type AreaEvents = 'gameover' | 'tailcountchange';

const initialEventSubscriptions: Record<AreaEvents, Function[]> = {
    'gameover': [],
    'tailcountchange': [],
};

export class Area {
    private readonly pixi: PIXI.Application;
    private tiles: Tile[][] = [];
    public player: Player;
    private subscriptions: Record<AreaEvents, Function[]> = structuredClone(initialEventSubscriptions);

    constructor(pixi: PIXI.Application) {
        this.pixi = pixi;
        this.player = new Player(this.pixi);
    }

    private get height() {
        return this.pixi.renderer.height;
    }

    private get width() {
        return this.pixi.renderer.width;
    }

    setup() {
        this.prepareTiles();
    }

    restart() {
        this.cleanup();
        this.setup();
        this.generateContent();
    }

    subscribe(eventName: AreaEvents, handler: Function) {
        const handlers = this.subscriptions[eventName];
        const isAlreadySubscribed = handlers.find((func) => func === handler);
        if (isAlreadySubscribed) {
            return;
        }

        this.subscriptions[eventName].push(handler);
    }

    unsubscribe(eventName: AreaEvents, handler: Function) {
        const handlers = this.subscriptions[eventName];
        this.subscriptions[eventName] = handlers.filter((func) => func !== handler);
    }

    broadcast(eventName: AreaEvents, data?: Record<string, any>) {
        this.subscriptions[eventName].forEach((handler) => handler(data));
    }

    cleanup() {
        this.tiles.forEach((row) => {
            row.forEach((tile) => {
                tile.destroy();
                this.pixi.stage.removeChild(tile.entity as PIXI.DisplayObject);
            })
        })
        this.tiles = [];
        this.player.reset();
    }

    getTilesCount() {
        return _.flatten(this.tiles).length;
    }

    nextState() {
        const state = this.movePlayer();
        if ([GAME_STATE.WON, GAME_STATE.LOST].includes(state)) {
            this.broadcast('gameover');
            this.restart();
        }
    }

    renewTails() {
        const tiles = this.getTails();
        tiles.forEach((tile) => {
            const tail = tile.content as Tail;
            tail.hp--;

            if (tail.hp <= 0) {
                tile.setContent(null);
            }
        });
    }

    getTails(): Tile[] {
        return _.flatten(this.tiles).filter((tile) => tile.contentType === TILE_CONTENT_TYPE.PLAYER_CURRENT_TAIL);
    }

    movePlayer() {
        this.player.shiftDirection();

        const currentPosition: TilePositionMeta = this.getPlayerPosition();
        const surroundings = this.getSurroundings(currentPosition.i, currentPosition.j);
        const nextPosition = this.player.getNextPosition(surroundings);
        if (nextPosition === null) {
            return GAME_STATE.LOST;
        }

        if (currentPosition.tile !== null && nextPosition.tile !== null && nextPosition.tile !== currentPosition.tile) {
            if (nextPosition.tile.contentType === TILE_CONTENT_TYPE.FOOD) {
                const food = nextPosition.tile.content as Food;

                const tiles = this.getTails();
                tiles.forEach((tile) => {
                    const tail = tile.content as Tail;
                    tail.hp += food.score;
                });
                this.player.grow(food.score);
                this.broadcast('tailcountchange', {
                    tailCount: this.player.tailCount
                });

                this.generateFood(1);
            }

            const tail = new Tail(this.pixi, this.player.tailCount);
            currentPosition.tile.setContent(tail);
            this.renewTails();

            nextPosition.tile.setContent(this.player);
        }
        return GAME_STATE.ALIVE;
    }

    getPlayerPosition() {
        const playerPosition: TilePositionMeta = {
            i: -1,
            j: -1,
            tile: null
        };
        for (let i = 0; i < this.tiles.length; i++) {
            const row = this.tiles[i];
            const j = row.findIndex((tile) => tile.contentType === TILE_CONTENT_TYPE.PLAYER_CURRENT_HEAD);

            if (j !== -1) {
                playerPosition.i = i;
                playerPosition.j = j;
                playerPosition.tile = this.tiles[i][j];
            }
        }
        return playerPosition;
    }

    getTile(horizontalIndex: number, verticalIndex: number) {
        const i = horizontalIndex;
        const j = verticalIndex;
        try {
            return { tile: this.tiles[i][j], i, j};
        } catch (e) {
            return { tile: null, i: -1, j: -1 };
        }
    }

    getSurroundings(horizontalIndex: number, verticalIndex: number): PositionSurroundings {
        return {
            [DIRECTION.NONE]: this.getTile(horizontalIndex, verticalIndex),
            [DIRECTION.LEFT]: this.getTile(horizontalIndex, verticalIndex - 1),
            [DIRECTION.RIGHT]: this.getTile(horizontalIndex, verticalIndex + 1),
            [DIRECTION.UP]: this.getTile(horizontalIndex - 1, verticalIndex),
            [DIRECTION.DOWN]: this.getTile(horizontalIndex + 1, verticalIndex)
        }
    }

    prepareTiles() {
        const tilesRowCount = Math.floor(this.height / TILE_SIZE.HEIGHT);
        const tilesColumnCount = Math.floor(this.width / TILE_SIZE.WIDTH);

        for (let i = 0; i < tilesRowCount; i++) {
            this.tiles.push([]);
            for (let j = 0; j < tilesColumnCount; j++) {
                const tile = new Tile(this.pixi, i, j);
                tile.setPosition(j * TILE_SIZE.WIDTH, i * TILE_SIZE.HEIGHT);
                this.tiles[i].push(tile);

                this.pixi.stage.addChild(tile.entity as PIXI.DisplayObject);
            }
        }
    }

    getRandomTiles(count: number): Tile[] {
        const tiles = _.flatten(this.tiles).filter((tile) => tile.isEmpty());
        return _.sampleSize(tiles, count);
    }

    getRandomFoodSize(): FoodSize {
        const roll = _.random(0, 100);

        if (roll < 50) {
            return FoodSize.Small;
        }

        if (roll < 80) {
            return FoodSize.Middle;
        }

        return FoodSize.Large;
    }

    generateFood(count: number) {
        const tiles = this.getRandomTiles(count);
        tiles.forEach((tile) => {
            const foodSize = this.getRandomFoodSize();
            const food = new Food(this.pixi, foodSize);
            tile.setContent(food);
        })
    }

    generateWalls(count: number) {
        this.tiles.forEach((row, rowIndex, rowArr) => {
            row.forEach((tile, colIndex, colArr) => {
                const isTopBorder = rowIndex === 0;
                const isBottomBorder = rowIndex === rowArr.length - 1;
                const isLeftBorder = colIndex === 0;
                const isRightBorder = colIndex === colArr.length - 1;
                if (isTopBorder || isBottomBorder || isLeftBorder || isRightBorder) {
                    const wall = new Wall(this.pixi);
                    tile.setContent(wall);
                }
            })
        })

        const tiles = this.getRandomTiles(count);
        tiles.forEach((tile) => {
            const wall = new Wall(this.pixi);
            tile.setContent(wall);
        })
    }

    spawnPlayer() {
        const [tile] = this.getRandomTiles(1);
        tile.setContent(this.player);
    }

    generateContent() {
        const tilesCount = this.getTilesCount();
        // Adding some walls
        const wallsCount = Math.floor(tilesCount / 30);
        this.generateWalls(wallsCount);
        // Adding some food
        const foodCount = Math.floor(tilesCount / 40)
        this.generateFood(foodCount);
        // Adding player
        this.spawnPlayer();
    }
}
