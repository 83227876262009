import * as PIXI from 'pixi.js';

import {CubeSnakeRenderer} from '../renderer';
import { Area } from './area';

export enum EngineEvent {
    Pause = 'PAUSE',
    Unpause = 'UNPAUSE',
    Resize = 'RESIZE',
    PlayerCurrentGrow = 'PLAYER_CURRENT_GROW',
    GameOver = 'GAME_OVER',
}

const initialSubscriptions: Record<EngineEvent, Function[]> = Object.values(EngineEvent).reduce((acc, eventName) => {
    const key = eventName as EngineEvent;
    acc[key] = [];
    return acc;
}, {} as Record<EngineEvent, Function[]>);

class Engine {
    private isInitialized: boolean = false;
    private root: HTMLElement | null = null;
    private ticksCounter: number = 0;
    private ticksCounterMoveLimit: number = 10;
    private ticksCounterStayLimit: number = 0;
    private subscriptions: Record<EngineEvent, Function[]> = structuredClone(initialSubscriptions);
    private isPaused: boolean = false;

    public area: Area | null = null;

    constructor() {
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handlePlayerCurrentGrow = this.handlePlayerCurrentGrow.bind(this);
        this.handleGameOver = this.handleGameOver.bind(this);
    }

    setup(root: HTMLElement, options?: Partial<PIXI.IApplicationOptions>) {
        if (this.isInitialized) {
            return;
        }

        this.root = root;
        CubeSnakeRenderer.resize(this.root);

        this.area = new Area(CubeSnakeRenderer.pixi);
        // Embed pixi.js canvas to the page
        this.root.appendChild(CubeSnakeRenderer.pixi.view as HTMLCanvasElement);

        // Draw map
        this.area.setup();
        this.area.generateContent();

        // Set up broadcast
        this.area.subscribe('tailcountchange', this.handlePlayerCurrentGrow);
        this.area.subscribe('gameover', this.handleGameOver);

        document.addEventListener('keydown', this.handleKeyDown);
        this.start();
        this.isInitialized = true;
    }

    destroy() {
        this.root = null;
        this.area?.unsubscribe('tailcountchange', this.handlePlayerCurrentGrow);
        this.area = null;
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    set speed(value: number) {
        this.ticksCounterMoveLimit = value;
    }

    handlePlayerCurrentGrow(data: {tailCount: number}) {
        this.broadcast(EngineEvent.PlayerCurrentGrow, data);
    }

    handleGameOver() {
        this.broadcast(EngineEvent.GameOver);
    }

    handleWindowResize() {
        if (!this.area || !this.root) {
            return;
        }

        CubeSnakeRenderer.resize(this.root);
        this.area?.restart();
        this.broadcast(EngineEvent.Resize);
    }

    handleKeyDown(event: KeyboardEvent) {
        if (event.code === 'Space') {
            const eventName = this.isPaused ? EngineEvent.Unpause : EngineEvent.Pause;
            this.broadcast(eventName);
            this.isPaused = !this.isPaused;
        }
        this.player?.handleKeyboardEvents(event);
    }

    get player() {
        return this.area?.player ?? null;
    }

    private start() {
        CubeSnakeRenderer.pixi.ticker.add((delta) => {
            if (this.isPaused) {
                return;
            }

            if (this.ticksCounter < this.ticksCounterMoveLimit) {
                // this.area.animate();
                // this.area.player.entity.x += 10;
                // this.player.move(delta);
            }

            if (this.ticksCounter < this.ticksCounterMoveLimit + this.ticksCounterStayLimit) {
                this.ticksCounter++;
            } else {
                this.ticksCounter = 0;
                this.area?.nextState();
            }
        });
    }

    subscribe(eventName: EngineEvent, handler: Function) {
        const handlers = this.subscriptions[eventName];
        const isAlreadySubscribed = handlers.find((func) => func === handler);
        if (isAlreadySubscribed) {
            return;
        }

        this.subscriptions[eventName].push(handler);
    }

    unsubscribe(eventName: EngineEvent, handler: Function) {
        const handlers = this.subscriptions[eventName];
        this.subscriptions[eventName] = handlers.filter((func) => func !== handler);
    }

    broadcast(eventName: EngineEvent, data?: Record<string, any>) {
        this.subscriptions[eventName].forEach((handler) => handler(data));
    }
}

export const CubeSnakeEngine = new Engine();
