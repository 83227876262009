import { useCallback, useEffect, useState } from 'react';

import styles from './GameHeader.module.css';
import { Logo } from '../Logo/Logo';

import IconTails from './IconTails.png';
import { CubeSnakeEngine, EngineEvent } from '../../engine';

export function GameHeader() {
    const [tailCount, setTailCount] = useState(0);
    const [isPaused, setIsPaused] = useState(false);

    const handleTailCountChange = useCallback(({tailCount: value}: {tailCount: number}) => {
        setTailCount(value)
    }, []);

    const handleGameOver = useCallback(() => {
        setTailCount(0);
    }, []);

    const handlePause = useCallback(() => {
        setIsPaused(true);
    }, []);

    const handleUnpause = useCallback(() => {
        setIsPaused(false);
    }, [])

    useEffect(() => {
        CubeSnakeEngine.subscribe(EngineEvent.PlayerCurrentGrow, handleTailCountChange);
        CubeSnakeEngine.subscribe(EngineEvent.GameOver, handleGameOver);
        CubeSnakeEngine.subscribe(EngineEvent.Pause, handlePause);
        CubeSnakeEngine.subscribe(EngineEvent.Unpause, handleUnpause);
        return () => {
            CubeSnakeEngine.unsubscribe(EngineEvent.PlayerCurrentGrow, handleTailCountChange);
            CubeSnakeEngine.unsubscribe(EngineEvent.GameOver, handleGameOver);
            CubeSnakeEngine.unsubscribe(EngineEvent.PlayerCurrentGrow, handleTailCountChange);
            CubeSnakeEngine.unsubscribe(EngineEvent.Unpause, handleUnpause);
        }
    }, [handleTailCountChange, handleGameOver, handlePause, handleUnpause]);

    return (
        <div className={styles.GameHeader}>
            <div>
                <Logo/>
            </div>
            <div className={styles.GameHeaderStatus}>
                {isPaused && (
                    <div className={styles.GameHeaderPause}>
                        PAUSE
                    </div>
                )}
                <div className={styles.GameHeaderTailsContainer}>
                    <img alt="Tails" className={styles.GameHeaderTailsIcon} src={IconTails}/>
                    <div className={styles.GameHeaderTailsCount}>
                        x{tailCount + 1}
                    </div>
                </div>
            </div>
        </div>
    )
}