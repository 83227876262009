export enum DIRECTION {
    NONE = 'NONE',
    UP = 'UP',
    DOWN = 'DOWN',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT'
}

export const DIRECTION_TO_KEY_MAP: Record<DIRECTION, string[]> = {
    [DIRECTION.NONE]: [],
    [DIRECTION.UP]: ['KeyW', 'ArrowUp'],
    [DIRECTION.LEFT]: ['KeyA', 'ArrowLeft'],
    [DIRECTION.DOWN]: ['KeyS', 'ArrowDown'],
    [DIRECTION.RIGHT]: ['KeyD', 'ArrowRight']
};

export const KEY_TO_DIRECTION_MAP: Record<string, DIRECTION> = Object.entries(DIRECTION_TO_KEY_MAP).reduce<Record<string, DIRECTION>>((acc, entry) => {
    const [direction, valueArr] = entry as [DIRECTION, string[]];
    valueArr.forEach((key) => {
        acc[key] = direction;
    })

    return acc;
}, {});

export const DIRECTION_INCOMPATIBILITY_MAP: Record<DIRECTION, DIRECTION[] | null> = [
    [DIRECTION.UP, DIRECTION.DOWN],
    [DIRECTION.LEFT, DIRECTION.RIGHT]
].reduce((acc, [directionA, directionB]) => {
    acc[directionA] = [directionA, directionB];
    acc[directionB] = [directionB, directionA];
    return acc;
}, {
    [DIRECTION.NONE]: null
} as Record<DIRECTION, DIRECTION[] | null>);

export function isDirectionsCompatible(directionA: DIRECTION, directionB: DIRECTION): boolean {
    const incompatibleA = DIRECTION_INCOMPATIBILITY_MAP[directionA];
    const incompatibleB = DIRECTION_INCOMPATIBILITY_MAP[directionB];
    const isCompatibleA = !incompatibleA || !incompatibleA.includes(directionB);
    const isCompatibleB = !incompatibleB || !incompatibleB.includes(directionA);

    return isCompatibleA && isCompatibleB;
}

export enum DIRECTION_KIND {
    ABSTRACT = 'ABSTRACT',
    VERTICAL = 'VERTICAL',
    HORIZONTAL = 'HORIZONTAL'
}

export const DIRECTION_KIND_MAP = {
    [DIRECTION.NONE]: DIRECTION_KIND.ABSTRACT,
    [DIRECTION.UP]: DIRECTION_KIND.VERTICAL,
    [DIRECTION.DOWN]: DIRECTION_KIND.VERTICAL,
    [DIRECTION.RIGHT]: DIRECTION_KIND.HORIZONTAL,
    [DIRECTION.LEFT]: DIRECTION_KIND.HORIZONTAL,
}
