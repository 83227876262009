import { TileContent } from './index';
import { TILE_CONTENT_TYPE } from '../../const';
import * as PIXI from 'pixi.js';
import SpritePlayer from '../../../sprites/player_current.png';

const DEFAULT_TAIL_SCALE = 0.5;

export class Tail extends TileContent {
    public scale = DEFAULT_TAIL_SCALE;
    readonly type = TILE_CONTENT_TYPE.PLAYER_CURRENT_TAIL;
    public hp: number;

    constructor(pixi: PIXI.Application, hp: number) {
        super(pixi, SpritePlayer);
        this.hp = hp;
    }
}