import { GameControls } from '../GameControls/GameControls';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import styles from './GameFooter.module.css';

export function GameFooter() {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    return (
        <div className={styles.GameFooter}>
            {isTabletOrMobile && (
                <div className={styles.GameFooterColumn}>
                    <GameControls/>
                </div>
            )}
        </div>
    )
}